import React from 'react';


function WelcomeMessage() {
  return (
    <div className="WelcomeMessage">
        Now this is where I put all the crap I find interesting or made in my spare time.
        <br/>
        Will it work 24/7? I don't know, probably not, but when it does you might be entertained for a bit.
    </div>
  );
}

export default WelcomeMessage;